import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HorizonArt from '../images/horizon-art.png'
import Social from "../components/social-links"
import { Helmet } from "react-helmet"
import EmailForm from "../components/email-form"
import ProfilePic from '../images/james-profile-pic.jpg'


const IndexPage = () => (
  
  <Layout>
  <Helmet>
  <script>
      {`
        var cpm = {};
        (function(h,u,b){
        var d=h.getElementsByTagName("script")[0],e=h.createElement("script");
        e.async=true;e.src='https://cookiehub.net/c2/9410b03d.js';
        e.onload=function(){u.cookiehub.load(b);}
        d.parentNode.insertBefore(e,d);
        })(document,window,cpm);
      `}
    </script>
</Helmet>
 
    <SEO title="naideia" />
    <div className="intro-section-inner">
    <div className="about-nav">
    <a className="subnav" href="/about"><h4 id="about-naidieia" className="about-subnav">About Naideia</h4></a>
      <h4 className="about-subnav">|</h4>
      <a className="subnav" href="/about-james"><h4 id="about-james" className="about-subnav selected">About James</h4></a>
    </div>
      <hr />
      <img className="naideia-profile-pic" src={ProfilePic}></img>
       <div id="about">
        <p>
        James was exposed to making music at a young age. His mother often improvised and sang silly songs and his father was a multi-instrumentalist, amassing a number of instruments throughout his life. James learned to play the flute in elementary school, but would play drums whenever his cousin left his drum set at their grandmother’s house. Eventually, James received a small electronic drum set as a gift so he could play without making too much noise. 
        </p>
        <p>
        He began playing electric guitar at 13 years old and often practiced for hours on end. Other than guidance from his father, he was largely self-taught. Growing up in the 1990s and early 2000s, James’ early influences were the sounds of post-grunge and nu metal bands played on mainstream radio at the time such as Creed, Staind, P.O.D., Linkin Park, and Deftones. 
        </p>
        <p>
        James graduated to heavier music around the time he entered high school, listening to the metalcore and melodic death metal bands popular at the time, such as Norma Jean, All That Remains, As I Lay Dying, and Killswitch Engage. James frequented the metal clubs and venues around Dallas, TX even as a high-schooler. His father would drop him and groups of friends off at places like The Door in <a href="https://en.wikipedia.org/wiki/Deep_Ellum,_Dallas">Deep Ellum</a> and pick them up after shows. He would frequently have friends over to jam and play covers.
        </p>
        <p>
        James’ interest in electronic music began after hearing his father’s casual electronic music experimentation. He then began to pay more attention to the kinds of music used in movies and video games, particularly the electronic music elements from Twisted Metal III (1999), The Matrix (1999), and The Animatrix (2003).
        </p>
        <p>
        Around 16 years old, James began experimenting with home recording. His first DAWs were limited versions of Cubase and Fruity Loops. He would record guitar using Cubase and then create drum sections in Fruity Loops to import into the projects. 
        </p>
        <p>
        All throughout high school, James played in small local bands both in and outside of school, usually as a guitarist or drummer. His first gigging band was called Saving Normandy, where he played rhythm guitar. The band produced a few poor-quality recordings played only a handful of shows, the most memorable being on top of a bathroom at a skatepark without a PA system. 
        </p>
        <p>
        James became more serious about music theory and production in his mid-teens and began taking classical guitar lessons to strengthen his knowledge of the instrument. These lessons culminated in a single recital at Eastfield College the summer before his senior year of high school. He discontinued lessons because his instructor would disappear for days on suspected drug binges. 
        </p>
        <p>
        In 2008, James joined Dallas pop punk band <a href="https://soundcloud.com/page9">Page 9</a> as a rhythm guitarist. This was his first serious band that performed regularly and had a respectable local following. During that time, the band opened locally for a number of larger bands and touring acts such as The Afters, Mae, and The Secret Handshake. The band also enjoyed a bit of radio play on a local terrestrial radio station. 
        </p>
        <p>
        James also worked as a Promotions Assistant for Clear Channel communications for 6 months of 2008. He was often sent to concerts around Dallas to promote the local radio stations. He was laid off along with thousands of other people during The Great Recession.   
        </p>
        <p>
        Although James was focused on his college coursework, from 2009-2010 he would sometimes co-write and fill in for Geronimo’s Cadillac, a southern rock band started by a long-time friend, Dan Verhoeven. The band played the bar circuit in Deep Ellum but ultimately disbanded shortly after it began.
        </p>
        <p>
        James left Page 9 for personal reasons but was asked shortly thereafter to audition for post-hardcore band Hopeless Romantica as their bassist. It ended up being a good fit and the band eventually changed the name to <a href="https://www.facebook.com/AlexaReigns/">Alexa Reigns</a>. Alexa Reigns enjoyed moderate local success, culminating in winning a battle of the bands and subsequently opening for Breathe Carolina and Chiodos during their Dallas performance of the “Scream It Like You Mean It 2011” tour on July 29, 2011. The band went on hiatus after that show.
        </p>
        <p>
        A few months following the Breathe Carolina show, John Chambers—the lead vocalist for Alexa Reigns—invited James to play banjo with a cover band scheduled to open for Travis Tritt. On September 17, 2011, the band performed at the New Haven Amphitheater at New Haven Golf Club in Texarkana, Arkansas.
        </p>
        <p>
        Throughout college, James wrote, recorded, and performed his own folk music. He submitted entries in several folk competitions and was invited to perform at a University of North Texas showcase for a chance to play at the Kerrville Folk Festival. He didn’t win, but the experience encouraged him to continue writing his own music. 
        </p>
        <p>
        In 2013, James collected eight of his favorite folk and folk-rock songs written during college and re-recorded them over the course of three months for an independent release. He titled the project “Jamie Bones” and named the album “<a href="https://open.spotify.com/album/4i0l8qyPPCd42AcYELGcXQ?si=WD_WxjRiQfGcvpP4NmT2Cg">Alligator Brain</a>”, a reference to acting without thinking. He sent a couple of unsolicited copies to local radio stations and shared the album with friends, but ultimately never promoted it. The goal of the project was mostly for catharsis and to catalog early recordings. 
        </p>
        <p>
        After “Alligator Brain” James took a 6-year break from music to focus on his graphic design career, but continued making music as hobby. In 2019, he began replacing his obsolete equipment and started writing new material. He decided to focus on electronic music as a new challenge and dial in specifically on drum and bass, a genre he’d enjoyed since his early teens but not explored thoroughly. 
        </p>
        <p>
        James had some trouble selecting a subgenre to focus on, but after hearing Kumarion’s “Wanna” single and reading an interview from him describing his influences, James began to learn more about Neurofunk and Leftfield Bass artists such as Noisia and The Upbeats. Inspired by the speed and gritty sounds reminiscent of his early metal influences but with the groove of DnB, James opened the door to a flood of new inspiration. 
        </p>
        <p>
        He decided to call his project “naideia”, a word derived from the ancient Greek concept of <a href="https://en.wikipedia.org/wiki/Paideia">paideia</a>—spelled παιδεία—which described the education of well-rounded people considered to be the ideal members of society. By replacing the pi symbol (π) with a lowercase “n”, James created a new word unique to this project (and avoided having to use special characters when typing the name).  
        </p>
        <p>
        Naideia released the first EP, titled the "<a href="https://open.spotify.com/album/4ssd28fFEb0JkvtVgvFxS6?si=0OZZG3k7QIKPwRzPc573mQ">Linked EP</a>” in February 2021 and a single, “<a href="https://open.spotify.com/album/32D6R9YnLzERLLQoFPqF9m?si=0NmqWxkdRmmzhB9iGiXakQ">Horizon</a>”, a month later. With several releases already in 2021 and an extensive backlog of works in progress, expect more tunes to be released in the coming months.
        </p>
        <p>
        When James is not making music, he's busy working as the Design Lead for the <a href="https://openinfra.dev/">Open Infrastructure Foundation</a>. In his free time, he enjoys practicing photography, motorcyling, and traveling whenever possible. 
        </p>
        <p>
        <a href="https://linktr.ee/jamescole"><strong>SEE MORE</strong></a>  
        </p>
      </div>
    </div>
    <EmailForm />
    <footer>
      <Social />
      <p>Naideia is an electronic music project by multi-instrumentalist and producer, <a href={"https://linktr.ee/jamescole"}>James Cole</a>.</p>
      <p>© James Cole</p>
    </footer> 

  </Layout>
)

export default IndexPage
